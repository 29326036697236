import PropTypes from 'prop-types'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'

export const Header = ({ title }) => {
  const { t } = useTranslation('common')

  return (
    <div className='oglasi-header'>
      <p>{title || t('category_page_title')}</p>
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string
}
