import React from 'react'
import PropTypes from 'prop-types'
import withTranslation from 'next-translate/withTranslation'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Router from 'next/router'
import { getCurrentGroup } from 'SRC/modules/groups/selectors'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { getInfo } from 'SRC/modules/users/detail/selectors'
import { getSearchAsUrl, getSearchHrefUrl, prepareFilterValues } from 'SRC/modules/ads/filter/functions'
import config from 'SRC/config/config.json'

export class Panel extends React.PureComponent {
  constructor (props) {
    super(props)

    this.items = config.adsList.panelItems
  }

  onPanelItemClick = e => {
    const key = Number(e.currentTarget.dataset.id)

    const {
      filterValues,
      formStyle,
      currentPage,
      currentSubcategory,
      currentGroup,
      baseAs,
      baseHrefQuery,
      i18n
    } = this.props

    const locale = i18n?.lang?.toUpperCase() || 'ME'

    const preparedFilterValues = prepareFilterValues(filterValues)

    // Default sortBy param
    if (!preparedFilterValues.hasOwnProperty('sortBy')) {
      preparedFilterValues.sortBy = 'dateDesc'
    }

    const panelFilter = this.items[key]

    // set panel filter value or unset if it exists
    if (preparedFilterValues.hasOwnProperty(panelFilter.code)) {
      try {
        if (preparedFilterValues[panelFilter.code] === JSON.parse(panelFilter.value)) {
          delete preparedFilterValues[panelFilter.code]
        } else {
          preparedFilterValues[panelFilter.code] = JSON.parse(panelFilter.value)
        }
      } catch (err) {
        if (preparedFilterValues[panelFilter.code] === panelFilter.value) {
          delete preparedFilterValues[panelFilter.code]
        } else {
          preparedFilterValues[panelFilter.code] = panelFilter.value
        }
      }
    } else {
      try {
        preparedFilterValues[panelFilter.code] = JSON.parse(panelFilter.value)
      } catch (err) {
        preparedFilterValues[panelFilter.code] = panelFilter.value
      }
    }

    let actualSubcategorySeo = null

    if (currentSubcategory) actualSubcategorySeo = currentSubcategory.seo
    else actualSubcategorySeo = currentGroup ? currentGroup.seo : null

    const hrefUrl = getSearchHrefUrl()
    const href = {
      pathname: hrefUrl,
      query: {
        ...baseHrefQuery,
        ...preparedFilterValues,
        category: actualSubcategorySeo,
        pageNumber: currentPage,
        formStyle
      }
    }

    const asUrl = getSearchAsUrl(actualSubcategorySeo, baseAs, locale)
    const as = {
      pathname: asUrl,
      query: {
        ...preparedFilterValues,
        pageNumber: currentPage,
        formStyle
      }
    }

    Router.push(href, as)
  }

  getIsActive = itemId => {
    const { filterValues } = this.props
    const item = this.items[itemId]

    let isActive = false

    if (filterValues.hasOwnProperty(item.code)) {
      try {
        if (filterValues[item.code] === JSON.parse(item.value)) {
          isActive = true
        }
      } catch (err) {
        if (filterValues[item.code] === item.value) {
          isActive = true
        }
      }
    }

    return isActive
  }

  render () {
    const { i18n: { t } } = this.props

    return (
      <div className='oglasi-filter-content'>
        <ul>
          {
            Object.keys(this.items).map(key => <li key={key}>
              <span
                onClick={this.onPanelItemClick}
                data-id={key}
                className={`oglasi-filter-content_item${this.getIsActive(key) ? ` active` : ``}`}
              >
                <i className={`${this.items[key].classname} ico`} /> {t(`common:panel_${this.items[key].code}_label`)}
              </span>
            </li>)
          }
        </ul>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const user = getInfo(state)
  
  let baseAs = '/'
  let baseHrefQuery = {}

  if (ownProps.isUserSearch && user && user.type && user.username) {
    baseAs = `/${user.type.seo}/${user.seo || user.username}/`
    baseHrefQuery = { username: user.seo || user.username, userType: user.type.seo }
  }

  return {
    currentSubcategory: getCurrentSubCategory(state),
    currentGroup: getCurrentGroup(state),
    baseAs,
    baseHrefQuery
  }
}

Panel.propTypes = {
  baseAs: PropTypes.string,
  baseHrefQuery: PropTypes.object,
  filterValues: PropTypes.object.isRequired,
  formStyle: PropTypes.string,
  currentPage: PropTypes.number,
  currentSubcategory: PropTypes.object,
  currentGroup: PropTypes.object
}

Panel.defaultProps = {
  currentPage: 1,
  filterValues: {}
}

const enhance = compose(
  withTranslation,
  connect(mapStateToProps)
)

export default enhance(Panel)
