export const routes = [
  '/sms-oglasi',
  'profil',
  '/registracija',
  '/dodaj',
  '/prodavnice',
  '/auto-placevi',
  '/auto-saloni',
  '/auto-otpadi',
  '/adresar'
]
